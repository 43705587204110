<template>
  <unauthorized-access v-if="!isAuthorized">
    You are signed in as {{ currentUser.email }}.
  </unauthorized-access>
  <div
    v-else
    class="container"
  >
    <header>
      <h3>{{ content }}</h3>

      <div>
        <ul class="list-group">
          <li class="list-group-item">
            <router-link :to="{ name: 'admin_dictionary' }">
              Add/Edit Terms
            </router-link>
          </li>
          <li class="list-group-item">
            <router-link :to="{ name: 'admin_category' }">
              Add/Edit Categories
            </router-link>
          </li>
        </ul>
      </div>
    </header>
  </div>
</template>

<script>
import UnauthorizedAccess from "@/components/admin/UnauthorizedAccess";
import AdminAuthorizationCheck from "@/services/admin/authorization-check";
import UserService from "../services/user.service";

export default {
  name: "Admin",
  components: {
    UnauthorizedAccess
  },
  setup() {
    return {
      ...AdminAuthorizationCheck()
    };
  },
  data() {
    return {
      content: "",
    };
  },
  mounted() {
    UserService.getAdminBoard().then(
      (response) => {
        this.content = response.data;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
};
</script>
